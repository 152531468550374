<template>
  <div>
    <Menu :menu="accessMenu" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu: [
        {
          title: 'Все Дилеры',
          link: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST,
        },
        {
          title: 'Бонусные баллы Дилеров',
          link: ADDSELLERS_ADMIN_ROUTES.DEALERS.LIST_WITH_BONUS_COUNT,
        },
        {
          title: 'Заметки',
          link: ADDSELLERS_ADMIN_ROUTES.DEALERS.NOTES.LIST,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
